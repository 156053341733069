<template>
	<div>
		<!-- If mind map found -->
		<StandardMindmap v-if="map_id" :prop_map_id="map_id"></StandardMindmap>

		<!-- Else, show empty -->
		<NoData v-else heading="app.no_maps_yet" :show_action="false"></NoData>
	</div>
</template>

<script>
import { mapState } from "vuex";
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import NoData from "@/components/shared/NoData.vue";
import { findFromArray } from "@/utils/helpers";

export default {
	name: "StandardMapWork",

	data() {
		return {
			map_id: null,
		};
	},

	components: {
		StandardMindmap,
		NoData,
	},

	computed: {
		...mapState({
			process_status: (state) => state.process.process_status,
			current_module: (state) => state.research.current_module,
			current_stage: (state) => state.research.current_stage,
		}),
	},

	mounted() {
		this.setProcessMap();
	},

	methods: {
		async setProcessMap() {
			try {
				this.$loader.start();

				let response, result;

				response = await this.$store.dispatch("mindmap/list", {
					category: this.$defines.SYSTEM_CATEGORY,
				});
				if (!response || !response.data || !response.data.length) return;

				response = response.data;

				/**
				 * If more than one maps created then find the map which
				 * was selected by the user. Else, pick the first one.
				 */
				result =
					response.length > 1
						? await this.findSuitableMap(response)
						: response[0];
				// setp process map id
				this.map_id = result.id;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async findSuitableMap(maps) {
			// Search the selected map in the maps array
			let result = findFromArray(maps, "selected", true);
			// If selected map not found in maps array
			if (!result) {
				throw new Error("app.ntfy.err.selected_map_not_found", {
					cause: "werCustom",
				});
			}
			return result;
		},
	},
};
</script>

<style></style>
